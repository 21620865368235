import React from 'react'
import Loading from './../Loading/Loading'
import SimpleSlider from './../Slider/SimpleSlider'
import Infobox from './../Infobox/Infobox'
import Footnote from './../Footnote/Footnote'
import SimpleTabs from './../Tabs/SimpleTabs'
import {
  getApiUrl,
  getLanguage,
  MULTISELECT_SUBCATEGORY_ID,
  DEFAULT_EQUIPMENT
} from '../../config'
import {
  filterOptionsAlreadyInCart,
  isAtLeastOneOptionInCart,
  filterOptionsNotInCart,
  doHaveInclusionsExclusions,
  doHaveExclusionsDependencies,
  doHaveOptionsExclusions,
  doHaveOptionsInclusions,
  doHaveOptionsOptions,
  findSelectedPackagesByCode,
  findtPackagesByDetailItems,
  redirectToStart,
  translate,
  recalculatePricesAndWeights,
  markItemsAsSelected,
  deleteInCart,
  saveToCart,
  loadExtendedAttributes,
  sortKeys
} from '../../helpers'
import { getAssetUrl, getSlidesPath } from '../../config'
import { AppContext } from '../../Data/ContextProvider'
import ConflictOverlay from './../Overlay/ConflictOverlay'
import MaxWeightOverlay from '../Overlay/MaxWeightOverlay'

class Interieur extends React.Component {
  constructor(props) {
    super(props)
    this.slider = React.createRef()
    this.state = {
      interieur: [],
      footnote: [],
      infobox: [],
      items: [],
      model: '',
      bedroomFlapOpen: 0
    }
  }

  resetConflictOverlayState = () => {
    this.setState({
      conflictItem: null,
      additionalInclusions: [],
      additionalItems: [],
      conflictingItem: null,
      conflictingItems: [],
      conflictingPackages: [],
      exclusions: [],
      optionsExclusions: [],
      optionsExcludedPackages: [],
      dependenciesForExclusions: [],
      exclusionInCombonation: []
    })
  }

  closeConflictOverlay = () => {
    this.context.toggleConflictOverlay()
  }

  closeMaxWeightOverlay = () => {
    this.context.maxWeightOverlay(false)
  }

  filterAlreadySelectedItems = () => {
    let selectedItems = []

    if (this.context.cart.packages) {
      this.context.cart.packages.map((paket) => {
        if (paket !== null) {
          paket.items.map((item) => {
            if (item.details) {
              item.details.map((detail) => {
                return selectedItems.push(detail.code)
              })
            }
            return null
          })
        }
        return null
      })
    }

    const items = this.state.items

    items.map((level, index) => {
      level.items.map((item) => {
        if (selectedItems.includes(item.code)) {
          return (item.disabled = true)
        } else {
          return (item.disabled = false)
        }
      })
      return null
    })

    this.setState({ items: items })
  }

  addToChapter = async (parent_index, item, chapter = this.props.chapter) => {
    const title = item.chapterTitle
    const new_cart = saveToCart(
      item,
      chapter,
      this.context.cart,
      parent_index,
      title
    )
    await this.context.updateCart(new_cart)
    this.setState(
      {
        items: await markItemsAsSelected(this.state.items, new_cart, chapter)
      },
      async () => {
        this.filterAlreadySelectedItems()
      }
    )

    this.context.maxWeightOverlay(this.context.cart.reachedMaxWeight)
  }

  removeFromChapter = async (item) => {
    if (item.tab === 'interieur') {
      // Bug: Schrankserienausstattung wird nicht richtig als ausgewählt angezeigt, wenn nur eine Option ausgewählt ist
      // Todo: Umschreiben in this.removeFromInterieur(item)
      this.addToInterieur(item)
    } else {
      const new_cart = deleteInCart(this.context.cart, item)
      await this.context.updateCart(new_cart)
      this.context.maxWeightOverlay(this.context.cart.reachedMaxWeight)
    }
  }

  buildParamsFromArgs = (view) => {
    const searchParamsData = {
      model: this.context.cart.model.imageKey,
      productId: this.context.cart.layout.productId,
      systemName: this.context.cart.layout.systemName,
      view
    }

    let modelOptions = []

    const globalOptions = ['backCushion', 'seatCushion', 'rearWall']

    if (this.context.cart.model.title === 'iSmove') {
      modelOptions = ['flap', 'cabinetDecor']
    } else if (this.context.cart.model.title === 'Flair') {
      modelOptions = [
        'furniture',
        'kitchenFlaps',
        'kitchenCabinet',
        // Multiselect Closet
        'closetEquipment',
        'closetSideShelf',
        'closetUpperShelf',
        'closetCubboard',
        'closetMicrowave',
        'closetDrawers',
        'closetTrouserHanger',
        // Multiselect Kitchen
        'kitchenEquipment',
        'kitchenDishwasher',
        'kitchenOven',
        'kitchenFridge'
      ]
    } else if (this.context.cart.model.title === 'Arto') {
      modelOptions = [
        'ceiling',
        'furniture',
        'kitchenFlaps',
        'kitchenCabinet',
        // Multiselect Kitchen
        'kitchenEquipment',
        'kitchenDishwasher',
        'kitchenOven',
        'kitchenFridge',
        // Multiselect Closet
        'closetEquipment',
        'closetSideShelf',
        'closetUpperShelf',
        'closetCubboard',
        'closetMicrowave',
        'closetDrawers',
        'closetTrouserHanger'
      ]
    }

    const options = [...globalOptions, ...modelOptions]

    options.forEach((option) => {
      if (this.state[option]) searchParamsData[option] = this.state[option]
    })

    const searchParams = new URLSearchParams(sortKeys(searchParamsData))

    return `/imagecreator/serveImage?${searchParams}`
  }

  randomInterieur = () => {
    const typeKeyImageKeyMapping = {}
    const itemsInCart = []
    const interieur = JSON.parse(JSON.stringify(this.state.interieur)) // deep copy!!! preventing to Maximum update depth exceeded errors
    let selectedItem, arraySize, randomIndex

    interieur.forEach((category, index) => {
      if (['970', '980'].includes(category.UKID)) {
        return
      }

      category.items.map((item) => (item.selected = false))

      arraySize = category.items.length
      randomIndex = Math.floor(Math.random() * arraySize)

      //Hack für Arto
      //Hack fuer die Klappen im Wohnraum und Schlafbereich
      //es werden nur jeweils 2 ersten Klappen ausgewaehlt
      if (this.context.cart.model.title === 'Arto') {
        if (index === 1 || index === 2) {
          if (randomIndex > 1) {
            randomIndex = Math.floor(Math.random() * 2)
          }
        }
      }

      selectedItem = category.items[randomIndex]
      selectedItem.selected = true

      itemsInCart.push({
        title: category.title,
        item: selectedItem
      })
      typeKeyImageKeyMapping[selectedItem.typeKey] = selectedItem.imageKey
    })

    this.setState({
      ...typeKeyImageKeyMapping,
      interieur
    })

    if (this.context.updateInterieur(itemsInCart)) {
      this.context.updateCart(recalculatePricesAndWeights(this.context.cart))
    }
  }

  handleSelectedTab = (parent_index) => {
    const status = parent_index === 2 ? 1 : 0
    // currentUKID ist für Flair (Wohnraum, Küche, Schrank) - Logik ist im Backend (imagecreator)
    const currentUKID = this.state.interieur[parent_index].UKID
    this.setState({ bedroomFlapOpen: status, currentUKID })
  }

  isSelectedItemInCart = (items, selectedItem) => {
    const filterItems = items.filter((item) => {
      return item.code === selectedItem.code
    })

    return filterItems.length > 0
  }

  addToInterieur = async (selectedItem) => {
    const typeKeyImageKeyMapping = {}
    const { interieur } = this.state
    const itemsInCart = this.context.cart.interieur

    const parentIndex = interieur.findIndex((e) => e.UKID === selectedItem.ukid)
    const itemIndex = interieur[parentIndex].items.findIndex(
      (e) => e.code === selectedItem.code
    )

    let items = itemsInCart[parentIndex]?.items ?? []

    const isMultiSelectNotDefault =
      MULTISELECT_SUBCATEGORY_ID.includes(selectedItem.ukid) &&
      !DEFAULT_EQUIPMENT.includes(selectedItem.code)

    let selectedItemIsInCart =
      isMultiSelectNotDefault && this.isSelectedItemInCart(items, selectedItem)

    selectedItem.selected = !selectedItemIsInCart

    if (isMultiSelectNotDefault) {
      if (selectedItemIsInCart) {
        items = items.filter((item) => item.code !== selectedItem.code)
        interieur[parentIndex].items[itemIndex].selected = false
        typeKeyImageKeyMapping[selectedItem.typeKey] = false
      } else {
        items.push(selectedItem)
        typeKeyImageKeyMapping[selectedItem.typeKey] = selectedItem.imageKey
        interieur[parentIndex].items[itemIndex].selected = true
      }

      if (items.length > 0) {
        itemsInCart[parentIndex] = {
          title: interieur[parentIndex].title,
          items
        }

        // Bug: Schrankserienausstattung wird nicht richtig als ausgewählt angezeigt, wenn nur eine Option ausgewählt ist
        // Siehe Funktion removeFromChapter (Zeile 133)
        interieur[parentIndex].items[0].selected = false
        typeKeyImageKeyMapping[interieur[parentIndex].items[0].typeKey] = false
      } else {
        interieur[parentIndex].items[0].selected = true

        itemsInCart[parentIndex] = {
          title: interieur[parentIndex].title,
          item: interieur[parentIndex].items[0]
        }

        typeKeyImageKeyMapping[selectedItem.typeKey] = false
        typeKeyImageKeyMapping[interieur[parentIndex].items[0].typeKey] =
          interieur[parentIndex].items[0].imageKey
      }
    } else {
      itemsInCart[parentIndex] = {
        title: interieur[parentIndex].title,
        item: selectedItem
      }

      interieur[parentIndex].items.map((item) => (item.selected = false))
      interieur[parentIndex].items[itemIndex].selected = true

      const closetEquipmentKeys = [
        'closetSideShelf',
        'closetUpperShelf',
        'closetCubboard',
        'closetMicrowave',
        'closetDrawers',
        'closetTrouserHanger'
      ]
      const kitchenEquipmentKeys = [
        'kitchenFridge',
        'kitchenDishwasher',
        'kitchenOven'
      ]

      if (selectedItem.typeKey === 'closetEquipment') {
        closetEquipmentKeys.forEach((key) => {
          typeKeyImageKeyMapping[key] = false
        })
      } else if (selectedItem.typeKey === 'kitchenEquipment') {
        kitchenEquipmentKeys.forEach((key) => {
          typeKeyImageKeyMapping[key] = false
        })
      }

      typeKeyImageKeyMapping[selectedItem.typeKey] = selectedItem.imageKey
    }

    // set typekeys/imagekeys for Image-Preview
    this.setState({ ...typeKeyImageKeyMapping })
    this.context.updateInterieur(itemsInCart)

    const newCart = recalculatePricesAndWeights(this.context.cart)
    this.context.updateCart(newCart)
    this.context.maxWeightOverlay(this.context.cart.reachedMaxWeight)
  }

  addXClusions = async () => {
    if (this.state.attributes.xExclusions) {
      for (const item of this.state.attributes.xExclusions) {
        const new_cart = await deleteInCart(this.context.cart, item)
        await this.context.updateCart(new_cart)
      }
    }

    if (this.state.attributes.xInclusions) {
      for (const item of this.state.attributes.xInclusions) {
        if (item.tab === 'exterieur') {
          const new_cart = await saveToCart(
            item,
            'exterieur',
            this.context.cart
          )
          await this.context.updateCart(new_cart)
        }
      }
    }

    const new_cart = await recalculatePricesAndWeights(this.context.cart)
    await this.context.updateCart(new_cart)

    this.setState({ attributes: {} })

    return
  }

  removeXClusions = async (attributes = this.state.attributes) => {
    let new_cart = this.context.cart

    if (attributes.xInclusions) {
      for (const item of attributes.xInclusions) {
        new_cart = await deleteInCart(new_cart, item)
      }
    }

    if (attributes.xExclusions) {
      for (const item of attributes.xExclusions) {
        if (item.tab === 'exterieur') {
          new_cart = await saveToCart(item, 'exterieur', new_cart)
        }
      }
    }

    new_cart = await recalculatePricesAndWeights(new_cart)
    await this.context.updateCart(new_cart)

    this.setState({ attributes: {}, automaticHiddenRemoveAttributes: {} })

    return
  }

  addAdditionalItems = () => {
    if (
      !this.state.additionalItems ||
      !Array.isArray(this.state.additionalItems) ||
      this.state.additionalItems.length < 1
    ) {
      return
    }

    this.state.additionalItems.forEach((item) => {
      if (item.tab === 'exterieur') {
        // const new_cart = saveToCart(item, 'exterieur', this.context.cart)
        // this.context.updateCart(new_cart)
      } else if (item.tab === 'packages') {
        const new_cart = saveToCart(item, 'packages', this.context.cart)
        this.context.updateCart(new_cart)
        this.closeConflictOverlay()
        return
      } else if (item.tab === 'interieur') {
        this.addToInterieur(item)
      } else {
        // const parent_index = this.findParentAndIndexForItem(item)
        const parent_index = item.category
        const chapter = item.tab
        this.addToChapter(parent_index, item, chapter)
      }
    })

    return
  }

  removeAdditionalItems = (overlayProps) => {
    if (overlayProps.exclusions) {
      overlayProps.exclusions.map((iTr) => {
        return this.removeFromChapter(iTr)
      })
    }

    if (overlayProps.optionsExclusions) {
      overlayProps.optionsExclusions.map((iTr) => {
        return this.removeFromChapter(iTr)
      })
    }

    if (overlayProps.action === 'addexl' && overlayProps.conflictingItems) {
      overlayProps.conflictingItems.map((iTr) => {
        return this.removeFromChapter(iTr)
      })
    }
  }

  removeConflictingPackage = async (overlayProps) => {
    const packagesToRemove = overlayProps.conflictingPackages
    const packageToRemove = overlayProps.conflictingItem
    const itemsToRemove = overlayProps.conflictingItems
    const item = overlayProps.item

    const packages = this.context.cart.packages

    const dependenciesForExclusions = overlayProps.dependenciesForExclusions
      ? overlayProps.dependenciesForExclusions
      : []

    if (overlayProps.action === 'del' && item) {
      this.removeFromChapter(item)
    } else if (overlayProps.action === 'exl' && item) {
      this.addToInterieur(item)
    }

    if (itemsToRemove) {
      itemsToRemove.map((iTr) => {
        return this.removeFromChapter(iTr)
      })
    }

    if (packageToRemove) {
      const p_i_p_remove = this.findParentAndIndexForPackage(packageToRemove)
      const packages = this.context.cart.packages
      packages[p_i_p_remove.parent_index].items.splice(p_i_p_remove.index, 1)
      this.context.updatePackages(packages)
    }

    if (packagesToRemove) {
      packagesToRemove.forEach((iTr) => {
        let p_i_p_remove = this.findParentAndIndexForPackage(iTr)
        packages[p_i_p_remove.parent_index].items.splice(p_i_p_remove.index, 1)
      })

      this.context.updatePackages(packages)
    }

    if (dependenciesForExclusions) {
      dependenciesForExclusions.map((iTr) => {
        return this.removeFromChapter(iTr)
      })
    }

    const attributes =
      this.state.automaticHiddenRemoveAttributes &&
      Object.getPrototypeOf(this.state.automaticHiddenRemoveAttributes) ===
        Object.prototype &&
      Object.keys(this.state.automaticHiddenRemoveAttributes).length > 0
        ? this.state.automaticHiddenRemoveAttributes
        : this.state.attributes

    await this.removeXClusions(attributes)

    this.closeConflictOverlay()
  }

  removeExclusionInCombonation = async (item) => {
    this.closeConflictOverlay()
    await this.addToInterieur(this.state.conflictItem)
    await this.removeFromChapter(item)
  }

  addConflictingItem = async (overlayProps) => {
    this.addToInterieur(this.state.conflictItem)
    this.addAdditionalItems()
    this.removeAdditionalItems(overlayProps)

    this.closeConflictOverlay()

    return
  }

  addConflictingPackage = async (overlayProps) => {
    await this.addXClusions()
    const additionalItems = overlayProps.additionalItems
    const item = overlayProps.item
    const itemsToRemove = overlayProps.conflictingItems
      ? overlayProps.conflictingItems
      : []
    const exclusions = overlayProps.exclusions ? overlayProps.exclusions : []
    const optionsExclusions = overlayProps.optionsExclusions
      ? overlayProps.optionsExclusions
      : []

    if (
      (overlayProps.action === 'add' || overlayProps.action === 'addexl') &&
      additionalItems
    ) {
      additionalItems.forEach((item) => {
        if (item.tab === 'exterieur') {
          const new_cart = saveToCart(item, 'exterieur', this.context.cart)
          this.context.updateCart(new_cart)
        } else if (item.tab === 'packages') {
          const new_cart = saveToCart(item, 'packages', this.context.cart)
          this.context.updateCart(new_cart)
          this.closeConflictOverlay()
          return
        } else {
          const parent_index = this.findParentAndIndexForItem(item)
          this.addToChapter(parent_index, item)
        }
      })
    }

    if (
      (overlayProps.action === 'add' || overlayProps.action === 'addexl') &&
      item
    ) {
      let parent_index = this.findParentAndIndexForItem(item)
      this.addToChapter(parent_index, item)
    }

    if (exclusions) {
      exclusions.map((iTr) => {
        return this.removeFromChapter(iTr)
      })
    }

    if (optionsExclusions) {
      optionsExclusions.map((iTr) => {
        return this.removeFromChapter(iTr)
      })
    }

    if (overlayProps.action === 'addexl' && itemsToRemove) {
      itemsToRemove.map((iTr) => {
        return this.removeFromChapter(iTr)
      })
    }

    this.closeConflictOverlay()
  }

  findParentAndIndexForItem = (item) => {
    let found = false
    let parent_index = 0

    if (this.props.items) {
      this.props.items.forEach((chassi, index) => {
        if (!found) {
          parent_index = index
        }
        chassi.items.forEach((chassi_item) => {
          if (chassi_item.code === item.code) {
            if (!found) {
              found = true
            }
          }
        })
      })
    }
    return parent_index
  }

  addItemWithOption = (overlayProps, option) => {
    let atIndex = 0
    this.addToInterieur(overlayProps.item)

    const additionalInclusions = this.state.additionalInclusions
    if (additionalInclusions.length > 0) {
      additionalInclusions.map((item) => {
        atIndex = this.findParentAndIndexForItem(item)
        if (item.tab === 'packages') {
          const new_cart = saveToCart(
            item,
            'packages',
            this.context.cart,
            atIndex
          )
          this.context.updateCart(new_cart)
        } else if (item.tab === 'chassis') {
          const new_cart = saveToCart(
            item,
            'chassis',
            this.context.cart,
            atIndex
          )
          this.context.updateCart(new_cart)
        } else if (item.tab === 'technology') {
          const new_cart = saveToCart(
            item,
            'technik',
            this.context.cart,
            atIndex
          )
          this.context.updateCart(new_cart)
        } else if (item.tab === 'structure') {
          const new_cart = saveToCart(
            item,
            'aufbau',
            this.context.cart,
            atIndex
          )
          this.context.updateCart(new_cart)
        } else if (item.tab === 'exterieur') {
          const new_cart = saveToCart(
            item,
            'exterieur',
            this.context.cart,
            atIndex
          )
          this.context.updateCart(new_cart)
        }
        return ''
      })
    }

    atIndex = this.findParentAndIndexForItem(option)
    if (option.tab === 'packages') {
      const new_cart = saveToCart(
        option,
        'packages',
        this.context.cart,
        atIndex
      )
      this.context.updateCart(new_cart)
    } else if (option.tab === 'interieur') {
      this.addToInterieur(option)
    } else if (option.tab === 'chassis') {
      const new_cart = saveToCart(option, 'chassis', this.context.cart, atIndex)
      this.context.updateCart(new_cart)
    } else if (option.tab === 'technology') {
      const new_cart = saveToCart(option, 'technik', this.context.cart, atIndex)
      this.context.updateCart(new_cart)
    } else if (option.tab === 'structure') {
      const new_cart = saveToCart(option, 'aufbau', this.context.cart, atIndex)
      this.context.updateCart(new_cart)
    } else if (option.tab === 'exterieur') {
      const new_cart = saveToCart(
        option,
        'exterieur',
        this.context.cart,
        atIndex
      )
      this.context.updateCart(new_cart)
    }

    // Exclusionen der gewaehlten Option loeschen
    if (option.exclusionsInCart) {
      option.exclusionsInCart.map((iTr) => {
        return deleteInCart(this.context.cart, iTr)
      })
    }

    // Inclusionen der gewaehlten Option hinzufügen
    if (option.inclusionsNotInCart) {
      option.inclusionsNotInCart.forEach((item) => {
        atIndex = this.findParentAndIndexForItem(item)
        if (item.tab === 'packages') {
          const new_cart = saveToCart(
            item,
            'packages',
            this.context.cart,
            atIndex
          )
          this.context.updateCart(new_cart)
        } else if (option.tab === 'chassis') {
          const new_cart = saveToCart(
            option,
            'chassis',
            this.context.cart,
            atIndex
          )
          this.context.updateCart(new_cart)
        } else if (item.tab === 'technology') {
          const new_cart = saveToCart(
            item,
            'technik',
            this.context.cart,
            atIndex
          )
          this.context.updateCart(new_cart)
        } else if (item.tab === 'structure') {
          const new_cart = saveToCart(
            item,
            'aufbau',
            this.context.cart,
            atIndex
          )
          this.context.updateCart(new_cart)
        } else if (item.tab === 'exterieur') {
          const new_cart = saveToCart(
            item,
            'exterieur',
            this.context.cart,
            atIndex
          )
          this.context.updateCart(new_cart)
        }
      })
    }

    if (overlayProps.optionsExclusions) {
      overlayProps.optionsExclusions.map((iTr) => {
        if (iTr.tab === 'interieur') {
          return this.addToInterieur(iTr)
        } else {
          return deleteInCart(this.context.cart, iTr)
        }
      })
    }

    if (overlayProps.optionsExcludedPackages) {
      overlayProps.optionsExcludedPackages.map((iTr) => {
        return deleteInCart(this.context.cart, iTr)
      })
    }

    this.closeConflictOverlay()
  }

  checkConflicts = async (selectedItem) => {
    let please_add = true

    const attributes = await loadExtendedAttributes(
      this.context.cart.layout.productId,
      selectedItem.code
    )

    // Exception for language: NO, FI: Adding package version of item needs Package - Package includes Clou Line Design (exchange price version with 0 EUR version)
    let specialAttributes = {}
    if (attributes.inclusions) {
      attributes.inclusions.map((item, key) => {
        if (item.xInclusions || item.xExclusions) {
          return (specialAttributes = item)
        }
        return ''
      })
    }
    this.setState({ attributes: specialAttributes })

    let automaticHiddenRemoveAttributes = {}
    if (attributes.exclusions) {
      attributes.exclusions.map((item) => {
        if (item.xInclusions || item.xExclusions) {
          return (automaticHiddenRemoveAttributes = item)
        }
        return ''
      })
    }
    this.setState({ automaticHiddenRemoveAttributes })

    // Sonderausstattung ist nicht mit einer anderen Sonderausstattung kombinierbar
    const exclusions = await filterOptionsAlreadyInCart(
      this.context.cart,
      attributes.exclusions
    )

    if (exclusions) {
      const packagesExclusions = findSelectedPackagesByCode(
        this.context.cart,
        exclusions
      )
      if (
        (exclusions && exclusions.length > 0) ||
        (packagesExclusions && packagesExclusions.length > 0)
      ) {
        let dependenciesForExclusions = await doHaveExclusionsDependencies(
          this.context.cart,
          exclusions
        )

        if (attributes.dependencies) {
          const itemDependenciesCodes = attributes.dependencies.map((item) => {
            return item.code
          })

          dependenciesForExclusions = dependenciesForExclusions.filter(
            (item) => {
              return !itemDependenciesCodes.includes(item.code)
            }
          )
        }

        this.setState({
          conflictItem: selectedItem,
          conflictingItems: exclusions,
          conflictingPackages: packagesExclusions,
          conflictAction: 'exl',
          dependenciesForExclusions: dependenciesForExclusions
        })
        please_add = false
      }
    }

    // Sonderausstattung ist nicht mit mehreren Sonderausstattungen in bestimmter Kombination kombinierbar

    if (
      attributes.exclusionInCombination &&
      attributes.exclusionInCombination.length > 1
    ) {
      const codesExclusionInCombination = attributes.exclusionInCombination.map(
        (item) => {
          return item.code
        }
      )

      const exclusionInCombination = await filterOptionsAlreadyInCart(
        this.context.cart,
        attributes.exclusionInCombination
      )

      if (
        exclusionInCombination.length ===
        attributes.exclusionInCombination.length
      ) {
        const filteredExclusionInCombination = exclusionInCombination.filter(
          (item) => {
            return !codesExclusionInCombination.includes(item.code)
          }
        )

        if (!filteredExclusionInCombination.length) {
          this.setState({
            conflictItem: selectedItem,
            conflictAction: 'noText',
            exclusionInCombonation: attributes.exclusionInCombination
          })
          please_add = false
        }
      }
    }

    // Produkt hat zwingende Ergänzungen / Erweiterungen => ConflictOverlay
    const inclusions = filterOptionsNotInCart(
      this.context.cart,
      attributes.inclusions
    )

    if (inclusions && inclusions.length) {
      const exclusionsForInclusions = await doHaveInclusionsExclusions(
        this.context.cart,
        inclusions
      )

      const conflictAction =
        exclusions && exclusions.length > 0 ? 'addexl' : 'add'

      this.setState({
        conflictItem: selectedItem,
        additionalItems: inclusions,
        conflictAction,
        exclusions: exclusionsForInclusions
      })
      please_add = false
    }

    // Produkt hat optionale Ergänzungen / Erweiterungen => ConflictOverlay
    const options = attributes.options
    const oneOptionInCart = await isAtLeastOneOptionInCart(
      this.context.cart,
      options
    )

    if (options && !oneOptionInCart && options.length) {
      // warum wird optionsExclusions nicht im state gespeichert?
      const optionsExclusions = await doHaveOptionsExclusions(
        this.context.cart,
        options
      )

      // warum ist hier optionsExclusions drin?
      const optionsInclusions = doHaveOptionsInclusions(
        this.context.cart,
        optionsExclusions,
        selectedItem
      )

      const optionOptions = doHaveOptionsOptions(
        this.context.cart,
        options,
        selectedItem
      )

      const packagesExclusions = findtPackagesByDetailItems(
        this.context.cart,
        exclusions
      )

      const additionalInclusions =
        JSON.stringify(inclusions) === JSON.stringify(optionsInclusions)
          ? []
          : inclusions

      this.setState({
        conflictItem: selectedItem,
        additionalInclusions: additionalInclusions,
        additionalItems: optionsInclusions,
        optionOptions,
        optionsExclusions: exclusions,
        optionsExcludedPackages: packagesExclusions,
        conflictAction: 'inc'
      })

      please_add = false
    }

    return please_add
  }

  handleInterieur = async (parent_index, i) => {
    this.resetConflictOverlayState()

    const interieur = this.state.interieur
    const selectedItem = interieur[parent_index].items[i]

    const compatible = await this.checkConflicts(selectedItem)

    if (!compatible) {
      this.context.toggleConflictOverlay()
    } else {
      this.addToInterieur(selectedItem)
    }
  }

  getSliderItems = () => {
    return [
      <SimpleSlider
        ref={this.slider}
        extraClasses="slider__slick-dots--position__higher slider--scaling-images slider--no-arrows"
        length="3"
        fade="1"
        speed="2000"
        dots="0"
        key="1"
      >
        <div
          className="slider__item slider__item--interieur"
          inverted={0}
          key="1"
        >
          <img src={getApiUrl() + this.buildParamsFromArgs(400)} alt="slide" />
        </div>
        <div
          className="slider__item slider__item--interieur"
          inverted={0}
          key="2"
        >
          <img src={getApiUrl() + this.buildParamsFromArgs(980)} alt="slide" />
        </div>
        <div
          className="slider__item slider__item--interieur"
          inverted={0}
          key="3"
        >
          <img src={getApiUrl() + this.buildParamsFromArgs(970)} alt="slide" />
        </div>
      </SimpleSlider>
    ]
  }

  // if user has already selected items
  initStateFromCart = () => {
    const typeKeyImageKeyMapping = {}

    const itemsInCart = this.context.cart.interieur || []
    const interieur = this.state.interieur

    if (itemsInCart.length) {
      const codesInCart = []

      itemsInCart.map((item, parent_index) => {
        // set typekeys/imagekeys for Image-Preview

        if (item.items && item.items.length > 0) {
          item.items.map((specialItem) => {
            typeKeyImageKeyMapping[specialItem.typeKey] = specialItem.imageKey
            return codesInCart.push(specialItem.code)
          })
        } else if (item.item) {
          typeKeyImageKeyMapping[item.item.typeKey] = item.item.imageKey
          // select codes of selected item in cart
          return codesInCart.push(item.item.code)
        } else {
          typeKeyImageKeyMapping[interieur[parent_index].items[0].typeKey] =
            interieur[parent_index].items[0].imageKey
          // select codes of selected item in cart
          return codesInCart.push(interieur[parent_index].items[0].code)
        }

        return ''
      })

      //select Items from Cart
      interieur.forEach((category) => {
        category.items.forEach((item) => {
          if (codesInCart.includes(item.code)) {
            item.selected = true
          }
        })
      })
    } else {
      // empty Init
      let selectedItem
      interieur.forEach((category) => {
        // immer nur das erste Element der jeweiligen Kategorie // Ausnahme für BE und Schiefer (UKID = 999)
        selectedItem =
          getLanguage() === 'be' && category.UKID === '999'
            ? category.items.at(-1)
            : category.items[0]
        selectedItem.selected = true
        itemsInCart.push({
          title: category.title,
          item: selectedItem
        })
        typeKeyImageKeyMapping[selectedItem.typeKey] = selectedItem.imageKey
      })

      this.context.updateInterieur(itemsInCart)
    }

    this.setState({
      ...typeKeyImageKeyMapping,
      interieur
    })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.config.exterieurTab2 !== this.context.config.exterieurTab2) {
      if (this.slider && this.slider.current) {
        const currentUKID =
          this.state.interieur[this.context.config.exterieurTab2].UKID

        if (this.context.cart.model.imageKey === 'A') {
          switch (currentUKID) {
            case '994':
            case '995':
            case '980':
              this.slider.current.changeActiveSlide(3)
              break
            case '970':
              this.slider.current.changeActiveSlide(2)
              break
            default:
              this.slider.current.changeActiveSlide(1)
          }
        } else {
          switch (currentUKID) {
            case '994':
            case '995':
            case '996':
            case '980':
              this.slider.current.changeActiveSlide(2)
              break
            case '993':
            case '970':
              this.slider.current.changeActiveSlide(3)
              break
            default:
              this.slider.current.changeActiveSlide(1)
          }
        }
      }
    }
  }

  async componentDidMount() {
    await this.context.changeSelectedTab(0, 'exterieurTab2')
    await this.context.updateCurrentNav(4)
    this.context.updateJumpmarks([])
    window.scrollTo(0, 0)

    try {
      fetch(
        getApiUrl() +
          `/data/interieur?modell=${this.context.cart.model.title}&grundriss=${this.context.cart.layout.title}&lang=` +
          getLanguage()
      )
        .then((response) => response.json())
        .then((data) => {
          const new_interieur = data.items ? data.items : []
          const footnote = data.footnote ? data.footnote : []
          const infobox = data.infobox ? data.infobox : []
          this.setState(
            { interieur: new_interieur, footnote, infobox },
            async () => {
              this.initStateFromCart()
              await this.context.addStepsCompleted(4)
              await this.context.updateCurrentParentTabs(new_interieur)
            }
          )
        })
    } catch (error) {
      redirectToStart()
    }
  }

  render() {
    if (!this.state.interieur.length) {
      return <Loading></Loading>
    }

    return (
      <div className="konfigurator__interieur">
        <div className="slider__wrapper">
          <button className="button button--cta" onClick={this.randomInterieur}>
            {translate('interieur', 'interieur_suggest')}
          </button>
          {this.getSliderItems()}
        </div>

        <SimpleTabs
          name="exterieurTab2"
          items={this.state.interieur}
          handleSelectedTab={this.handleSelectedTab}
          handleSelections={this.handleInterieur}
        />

        {Array.isArray(this.state.infobox) ? (
          this.state.infobox.map((content, index) => (
            <Infobox key={index} content={content} />
          ))
        ) : (
          <Infobox content={this.state.infobox} />
        )}

        {this.state.footnote.length > 0 && (
          <Footnote footnote={this.state.footnote} />
        )}

        {this.context.config.conflictOverlayOpen ? (
          <ConflictOverlay
            item={this.state.conflictItem}
            additionalItems={this.state.additionalItems}
            exclusions={this.state.exclusions}
            action={this.state.conflictAction}
            positiveCallBackAction={this.addConflictingItem}
            additionalInclusions={this.state.additionalInclusions}
            conflictingItem={this.state.conflictingItem}
            conflictingItems={this.state.conflictingItems}
            addItemWithOption={this.addItemWithOption}
            negativeCallBackAction={this.removeConflictingPackage}
            conflictingPackages={this.state.conflictingPackages}
            optionsExclusions={this.state.optionsExclusions}
            optionsExcludedPackages={this.state.optionsExcludedPackages}
            dependenciesForExclusions={this.state.dependenciesForExclusions}
            exclusionInCombonation={this.state.exclusionInCombonation}
            removeExclusionInCombonation={this.removeExclusionInCombonation}
          />
        ) : null}

        {this.context.maxWeightOverlayOpen && (
          <MaxWeightOverlay
            closeMaxWeightOverlay={this.closeMaxWeightOverlay}
            addItem={this.addToChapter}
            removeItem={this.removeFromChapter}
          />
        )}
      </div>
    )
  }
}

Interieur.contextType = AppContext
export default Interieur
